<template>
  <div class="section" id="page-footer">
    <div class="wrap">
      <b-row>
        <b-col cols="12">
          <div class="logo" @click="goto('#home')">
            <img src="../assets/images/footer.jpg" alt="" />
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-5 mb-4">
        <div class="bloco-intes">
          <div class="itens-footer">
            <a
              class="text-center"
              style="text-decoration: none"
              target="_blank"
              href="https://www.google.com/maps/place/Enc-Comunica%C3%A7%C3%A3o+Interativa/@-23.5730596,-46.6913064,17z/data=!3m1!4b1!4m5!3m4!1s0x94cef81cb75a9a37:0x71c471075ee50f94!8m2!3d-23.5730596!4d-46.6891177"
            >
              <b-icon icon="building"></b-icon>
              <p>Av. Faria Lima, 1811 - SP</p>
            </a>
          </div>
          <div class="itens-footer">
            <a
              class="text-center"
              style="text-decoration: none"
            >
              <b-icon icon="telephone-fill"></b-icon>
              <p>(11) 3791-7204</p>
            </a>
          </div>
          <div class="itens-footer email">
            <a
              @click="openForm()"
              v-scroll-to="'#scrollhere'"
              class="text-center"
              style="text-decoration: none"
            >
              <b-icon icon="envelope-fill"></b-icon>
              <p>Contato</p>
            </a>
          </div>
        </div>
        <form
          class="form-home"
          id="form"
          @submit.stop.prevent="onSubmit"
        >
          <div class="form-content" id="scrollhere">
            <input
              type="text"
              required
              name="nome"
              placeholder="Nome"
            />
            <input
              type="email"
              required
              name="email"
              placeholder="E-mail"
            />
            <input
              type="text"
              required
              name="telefone"
              placeholder="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
            <select name="assunto">
              <option value="Vazamento de dados">
                Vazamento de dados
              </option>
              <option selected value="Assunto geral">
                Assunto geral
              </option>
            </select>
            <textarea
              name="mensagem"
              required
              placeholder="Mensagem"
              style="resize: none"
            />
            <b-button
              type="submit"
              :disabled="sendingForm"
              class="bt-enc mt-4"
            >
              <span v-if="!sendingForm">Enviar</span>
              <b-spinner
                v-else
                small
                variant="warning"
              ></b-spinner>
            </b-button>
          </div>
        </form>
        <a
          class="privacy-policy"
          target="_blank"
          href="../assets/files/Política_de_Privacidade_de_Dados_Pessoais-Enc_Interativa.pdf"
        >
          Política de Privacidade
        </a>
        <p class="copy">
          © {{ (new Date()).getFullYear() }} ENC Interativa. Todos os direitos
          reservados.
        </p>
        <div class="bloco-redes">
          <div class="facebook-icon">
            <a
              class="text-center"
              style="text-decoration: none"
              href="https://www.facebook.com/encface"
              target="_blank"
            >
              <b-icon icon="facebook"></b-icon>
            </a>
          </div>
          <div class="linkedin-icon">
            <a
              class="text-center"
              style="text-decoration: none"
              href="https://www.linkedin.com/company/enc---comunicacao-interativa/about/"
              target="_blank"
            >
              <b-icon icon="linkedin"></b-icon>
            </a>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import FormService from '../services/FormService';
export default {
  data() {
    return {
      sendingForm: false,
    };
  },
  mounted() {
    this.formService = new FormService();
  },
  methods: {
    openForm() {
      document
        .querySelector('.form-home')
        .classList.toggle('motion-in');
    },
    onSubmit() {
      this.sendingForm = true;
      const form = document.getElementById('form');
      const formData = new FormData(form);

      const res = Object.fromEntries(formData);

      this.formService.postForm(res).then(() => {
        this.openForm();
        form.reset();
        this.$vToastify.clientSuccess(
          'Mensagem enviada com sucesso!',
          'Sucesso'
        );
        this.sendingForm = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/global';
//FORM CONTATO HOME

.form-home {
  transition: all 0.5s ease-in-out;
  height: 0;
  opacity: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border: 2px solid #f9c827;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  color: #242424;
  margin: 0 auto;
  overflow: hidden;
  select {
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px auto;
    padding: 5px 10px;
    color: #242424bf;
    width: 100%;
  }

  input,
  textarea {
    min-height: 40px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5px auto;
    padding: 5px 10px;
  }
  textarea {
    min-height: 200px;
  }
  button {
    margin: 0 auto !important;
    &:focus {
      box-shadow: none !important;
    }
    @media (max-width: 768px) {
      &:hover {
        background: transparent !important;
      }
    }
  }
  &.form-content {
    display: none;
  }
  &.motion-in {
    transition: all 0.5s ease-in-out;
    opacity: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto 1rem;
    height: 482px;
    &.form-content {
      transition: all 0.5s ease-in-out;
      display: block;
    }
  }
}

.spinner-border-sm {
  border-width: 0.2em !important;
}

.spinner-border {
  border: 0.15em solid currentColor !important;
  border-right-color: transparent !important;
}

//

.section {
  background-color: $white !important;
  height: 100% !important;
  padding-top: 50px !important;
  min-height: 342px !important;
}

.wrap {
  flex-flow: column !important;
  justify-content: center !important;
}

.logo {
  max-width: 100% !important;
  margin: 0 auto !important;

  img {
    width: 100% !important;
  }
}

.bloco-intes {
  display: flex;
  justify-content: space-evenly !important;
  align-items: center !important;
  max-width: 1200px !important;
}

.itens-footer {
  width: 249px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;

  p {
    padding-top: 10px !important;
    text-align: center !important;
  }

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $cor1 !important;
  }
}

.copy {
  text-align: center !important;
  padding-top: 15px !important;
}

.privacy-policy {
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.bloco-redes {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  max-width: 300px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding-top: 15px !important;

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $cor2 !important;
  }

  .facebook-icon {
    &:hover {
      transform: translateY(-5px) !important;
      transition: 0.3s !important;

      svg {
        fill: #1877f2 !important;
      }
    }
  }

  .twitter-icon {
    &:hover {
      transform: translateY(-5px) !important;
      transition: 0.3s !important;

      svg {
        fill: #2aa9e0 !important;
      }
    }
  }

  .linkedin-icon {
    &:hover {
      transform: translateY(-5px) !important;
      transition: 0.3s !important;

      svg {
        fill: #2867b2 !important;
      }
    }
  }
}

@media (max-width: $mobile) {
  .bloco-intes {
    flex-direction: column !important;

    .itens-footer {
      flex-direction: row !important;
      padding-bottom: 10px !important;

      svg {
        margin: 7px 4px 0 0 !important;
      }
    }
  }
}
</style>
